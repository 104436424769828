import { Pipe, PipeTransform } from '@angular/core';

type LimitToDigitsCountOptions = number | undefined;

@Pipe({
  name: 'ccLimitNumber',
  standalone: true,
})
export class LimitNumberPipe implements PipeTransform {
  transform(value: number, limitToDigits: LimitToDigitsCountOptions = undefined): string {
    if (!isNaN(limitToDigits) && limitToDigits < 1) throw new Error('limitToDigits must be a number greater than 0');

    if (limitToDigits === undefined) return value.toString();

    const limit = Math.pow(10, limitToDigits) - 1;
    if (value > limit) return `${limit}+`;

    return value.toString();
  }
}
